<template>
  <div class="iss-avatar-upload" @click="visible = true">
    <CloudUploadOutlined class="upload-icon" />

    <!--    <img :src="setAvatar(value)" class="22332333"/>-->
    <div class="headImg">
      <a-avatar :src="setAvatar(value)" :size="128" />
      <!--      <div ><UserOutlined /></div>-->
      <!--      <a-avatar :size="128" v-show="isShowHeader">-->
      <!--        <template #icon><UserOutlined /></template>-->
      <!--      </a-avatar>-->

      <div class="mask">
        <PlusOutlined class="mask-icon" />
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    title="修改头像"
    :width="600"
    centered
    @ok="handleOk"
  >
    <!--    <a-tabs v-model:activeKey="activeKey">-->
    <!--      <a-tab-pane key="houtianhuazi" tab="后田花子" />-->
    <!--      <a-tab-pane key="alixi" tab="阿里系" />-->
    <!--      <a-tab-pane key="myotee" tab="脸萌" />-->
    <!--      <a-tab-pane key="upload" tab="上传" />-->
    <!--    </a-tabs>-->
    <!--    <template v-if="['houtianhuazi', 'alixi', 'myotee'].includes(activeKey)">-->
    <!--      <a-row>-->
    <!--        <a-col-->
    <!--          v-for="item in list[activeKey]"-->
    <!--          :span="4"-->
    <!--          :key="item"-->
    <!--          @click="activeAvatar = item"-->
    <!--        >-->
    <!--          <div-->
    <!--            :class="`avatar-item${activeAvatar === item ? ' selected' : ''}`"-->
    <!--          >-->
    <!--            <img :src="setAvatar(item)" />-->

    <!--          </div>-->
    <!--        </a-col>-->
    <!--      </a-row>-->
    <!--    </template>-->
    <!--      -->
    <!--    <template   v-if="['upload'].includes(activeKey)" >-->
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="">
        <iss-image-upload
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
          v-model:value="activeUpload"
          @file="imageUpload"
        />
        <!--        <div class="font-color font-size">-->
        <!--          建议图片上传宽高比为1:1，大小不超过1M，格式为JPE、JPG、JPEG或PNG-->
        <!--        </div>-->
      </a-form-item>
    </a-form>

    <!--    </template>-->
  </a-modal>
</template>

<script>
// Col, Row,Tabs,
import { Avatar, Form } from 'ant-design-vue';
// UserOutlined
import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons-vue';
import IssImageUpload from '@/components/imageUpload';
import { setAvatar } from '@/utils';

export default {
  components: {
    // ATabs: Tabs,
    // ATabPane: Tabs.TabPane,
    // ARow: Row,
    // ACol: Col,
    CloudUploadOutlined,
    PlusOutlined,
    IssImageUpload,
    AAvatar: Avatar,
    AForm: Form,
    AFormItem: Form.Item,
    // UserOutlined,
  },
  props: {
    value: String,
  },
  data() {
    return {
      visible: false,
      isShowHeader: false,
      activeKey: 'upload',
      activeAvatar: 'default.jpg',
      activeUpload: '',
      // list: {
      //   houtianhuazi: [
      //     'default.jpg',
      //     '1d22f3e41d284f50b2c8fc32e0788698.jpeg',
      //     '2dd7a2d09fa94bf8b5c52e5318868b4d9.jpg',
      //     '2dd7a2d09fa94bf8b5c52e5318868b4df.jpg',
      //     '8f5b60ef00714a399ee544d331231820.jpeg',
      //     '17e420c250804efe904a09a33796d5a10.jpg',
      //     '17e420c250804efe904a09a33796d5a16.jpg',
      //     '87d8194bc9834e9f8f0228e9e530beb1.jpeg',
      //     '496b3ace787342f7954b7045b8b06804.jpeg',
      //     '595ba7b05f2e485eb50565a50cb6cc3c.jpeg',
      //     '964e40b005724165b8cf772355796c8c.jpeg',
      //     '5997fedcc7bd4cffbd350b40d1b5b987.jpg',
      //     '5997fedcc7bd4cffbd350b40d1b5b9824.jpg',
      //     'a3b10296862e40edb811418d64455d00.jpeg',
      //     'a43456282d684e0b9319cf332f8ac468.jpeg',
      //     'bba284ac05b041a8b8b0d1927868d5c9x.jpg',
      //     'c7c4ee7be3eb4e73a19887dc713505145.jpg',
      //     'ff698bb2d25c4d218b3256b46c706ece.jpeg',
      //   ],
      //   alixi: [
      //     'cnrhVkzwxjPwAaCfPbdc.png',
      //     'BiazfanxmamNRoxxVxka.png',
      //     'gaOngJwsRYRaVAuXXcmB.png',
      //     'WhxKECPNujWoWEFNdnJE.png',
      //     'ubnKSIfAJTxIgXOKlciN.png',
      //     'jZUIxmJycoymBprLOUbT.png',
      //   ],
      //   myotee: [
      //     '19034103295190235.jpg',
      //     '20180414165920.jpg',
      //     '20180414170003.jpg',
      //     '20180414165927.jpg',
      //     '20180414165754.jpg',
      //     '20180414165815.jpg',
      //     '20180414165821.jpg',
      //     '20180414165827.jpg',
      //     '20180414165834.jpg',
      //     '20180414165840.jpg',
      //     '20180414165846.jpg',
      //     '20180414165855.jpg',
      //     '20180414165909.jpg',
      //     '20180414165914.jpg',
      //     '20180414165936.jpg',
      //     '20180414165942.jpg',
      //     '20180414165947.jpg',
      //     '20180414165955.jpg',
      //   ],
      // },
    };
  },
  watch: {
    value(v) {
      if (v?.startsWith('http')) {
        this.activeUpload = v;
        // this.activeAvatar = '';
      } else {
        this.isShowHeader = !this.isShowHeader;
        // this.activeAvatar = v;
      }
    },
    activeAvatar(v) {
      v && (this.activeUpload = '');
    },
    activeUpload(v) {
      v && (this.activeAvatar = '');
    },
  },
  methods: {
    setAvatar,
    handleOk() {
      this.$emit('fnOk', this.activeAvatar || this.activeUpload);
      this.visible = false;
    },
    imageUpload(value) {
      this.activeUpload = value;
    },
  },
};
</script>

<style lang="less" scoped>
.headImg {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.iss-avatar-upload {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;
  .upload-icon {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .mask {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s ease 0s;
    .mask-icon {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: rgb(214, 214, 214);
    }
    &:hover {
      opacity: 1;
    }
  }
  .mask,
  img {
    width: 100%;
    max-width: 180px;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 0px;
  }
}
.avatar-item {
  padding: 7px;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  img {
    width: 100%;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    will-change: transform;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  &.selected {
    background-color: @primary-color;
  }
}
</style>
