<template>
  <a-upload
    class="iss-upload"
    :list-type="listType"
    v-bind="$attr"
    :data="file => ({ fileName: file })"
    :show-upload-list="false"
    :action="action"
    :headers="headers"
    :before-upload="beforeUpload"
    @change="handleChange"
  >
    <template v-if="listType === 'text'">
      <a-button>
        <template #icon>
          <loading-outlined v-if="loading" />
          <upload-outlined v-else />
        </template>
        上传
      </a-button>
    </template>
    <template v-else>
      <div v-if="value" class="upload-img">
        <img :src="value" />
        <delete-two-tone
          class="img-delete"
          twoToneColor="#f5222d"
          @click="handleClickByDelete"
        />
      </div>
      <div v-else>
        <loading-outlined v-if="loading" />
        <plus-outlined v-else />
        <div class="ant-upload-text">上传</div>
      </div>
    </template>
  </a-upload>
</template>

<script>
import { Upload } from 'ant-design-vue';
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
  DeleteTwoTone,
} from '@ant-design/icons-vue';
import errorMsg from 'ismartek-error-message';
import { setQKState } from '@/qiankun/appStore';
import { local } from '@/utils/storage';

export default {
  components: {
    AUpload: Upload,
    LoadingOutlined,
    PlusOutlined,
    UploadOutlined,
    DeleteTwoTone,
  },
  props: {
    value: String,
    listType: {
      type: String,
      default: 'text',
    },
    limitSize: Number,
  },
  data() {
    const { token, tenant } = this.$store.state.account;
    return {
      loading: false,
      // action: `${process.env.VUE_APP_API_SERVER}/file/upload?bizType=GOODS_IMAGE&isSingle=true`,
      action: `${process.env.VUE_APP_API_SERVER}connections_treasure_box/file/upload?bizType=images&isSingle=true`,
      headers: { token, tenant },
    };
  },
  methods: {
    beforeUpload(file) {
      const isJpgOrPng = ['image/jpeg', 'image/png'].includes(file.type);
      if (!isJpgOrPng) {
        this.$message.error('只能上传 JPG/JPEG/PNG 格式的图片!');
      }
      let size = 1;
      if (this.limitSize) size = Number(this.limitSize) * size;
      const isOK = file.size / 1024 / 1024 < size;
      if (!isOK) {
        this.$message.error(`图片大小不能超过 ${size}MB!`);
      }
      return isJpgOrPng && isOK;
    },
    handleChange({ file }) {
      // console.log('文件导入file', file);
      if (file.response.code === '0') {
        this.$message.success('图片上传成功！');
        this.$emit('file', file.response.data.dataUrlManage);
      } else {
        // this.$message.error(file.response.msg);
        errorMsg[file.response.code]
          ? this.$message.error(errorMsg[file.response.code])
          : this.$message.error(file.response.message);
        // 40001:会话超时，请重新登录
        if ([2001, 2002, 2003, 2004, 2005].includes(file.response.code)) {
          local.clear();
          setQKState({ noToken: true });
        }
      }

      // switch (file.response.code === 0) {
      //   case 'uploading':
      //     this.loading = true;
      //     break;
      //   case 'done':
      //     this.loading = false;
      //     // todo 需要改这部分封装组件的逻辑
      //     if (file.response.code === 0) {
      //       console.log('file.response.data.dataUrl', file.response.data.dataUrl)
      //       this.$message.success('图片上传成功！');
      //       this.$emit('update:value', file.response.data.dataUrl);
      //     } else {
      //       // this.$message.error(file.response.msg);
      //       errorMsg[file.response.code]
      //         ? this.$message.error(errorMsg[file.response.code])
      //         : this.$message.error(file.response.msg);
      //       // 40001:会话超时，请重新登录
      //       if ([2001, 2002, 2003, 2004, 2005].includes(file.response.code)) {
      //         local.clear();
      //         setQKState({ noToken: true });
      //       }
      //     }
      //     break;
      //   case 'error':
      //     this.loading = false;
      //     this.$message.error('图片上传失败！');
      //     break;
      //   default:
      //     break;
      // }
    },
    handleClickByDelete(e) {
      e.stopPropagation();
      this.$emit('update:value', '');
    },
  },
};
</script>

<style lang="less" scoped>
.iss-upload.ant-upload-picture-card-wrapper {
  display: block;
  :deep(.ant-upload.ant-upload-select-picture-card) {
    margin-bottom: 1px;
    border: 2px dashed @primary-3;
    &:hover {
      border-color: @primary-5;
    }
    .upload-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 86px;
      height: 86px;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
      .img-delete {
        position: absolute;
        top: -3px;
        right: -4px;
        z-index: 10;
      }
    }
    .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
.has-error .iss-upload :deep(.ant-upload.ant-upload-select-picture-card) {
  border-color: @error-color;
}
</style>
