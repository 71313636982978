<template>
  <div class="iss-main contactFrequency">
    <div class="gotoBack" @click="gotoBack">
      返回个人中心<RightOutlined class="ml-8" />
    </div>
    <a-tabs tab-position="left">
      <a-tab-pane key="base" tab="基本信息">
        <div class="iss-title">个人信息</div>
        <a-form
          layout="vertical"
          ref="formRef"
          :model="form"
          :rules="rules"
          :validate-trigger="['change', 'blur']"
        >
          <a-row :gutter="20">
            <a-col :span="14">
              <!--         v-bind="formRules.validateInfos.name"     -->
              <a-form-item label="姓名">
                <a-input
                  v-model:value="form.userName"
                  placeholder="请输入姓名"
                />
              </a-form-item>
              <a-form-item name="sex" label="性别">
                <a-radio-group v-model:value="form.sex">
                  <a-radio value="1">男</a-radio>
                  <a-radio value="0">女</a-radio>
                </a-radio-group>
              </a-form-item>

              <!--            v-bind="formRules.validateInfos.area"   -->
              <!--              <a-form-item label="地区">-->
              <!--                <a-select v-model:value="form.area" placeholder="请选择">-->
              <!--                  <a-select-option-->
              <!--                    v-for="item in companyRelationList"-->
              <!--                    :key="item.itemNameCn"-->
              <!--                    :value="item.itemNameCn"-->
              <!--                  >-->
              <!--                    {{ item.itemNameCn }}-->
              <!--                  </a-select-option>-->
              <!--                </a-select>-->
              <!--              </a-form-item>-->

              <a-form-item>
                <a-button type="primary" @click="handleClickBySave">
                  保存
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item>
                <iss-avatar v-model:value="form.avatar" @fnOk="getValue" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
      <!--      <a-tab-pane key="saft" tab="安全设置">-->
      <!--        <div class="iss-title">安全设置</div>-->
      <!--        &lt;!&ndash;        <a-list :dataSource="dataSource">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <template #renderItem="{ item }">&ndash;&gt;-->
      <!--        &lt;!&ndash;            <a-list-item>&ndash;&gt;-->
      <!--        &lt;!&ndash;              <template #actions>&ndash;&gt;-->
      <!--        &lt;!&ndash;                <a @click="handleClickByUpdate(item.key)">修改</a>&ndash;&gt;-->
      <!--        &lt;!&ndash;              </template>&ndash;&gt;-->
      <!--        &lt;!&ndash;              <a-list-item-meta&ndash;&gt;-->
      <!--        &lt;!&ndash;                  :title="item.title"&ndash;&gt;-->
      <!--        &lt;!&ndash;                  :description="item.description"&ndash;&gt;-->
      <!--        &lt;!&ndash;              />&ndash;&gt;-->
      <!--        &lt;!&ndash;            </a-list-item>&ndash;&gt;-->
      <!--        &lt;!&ndash;          </template>&ndash;&gt;-->
      <!--        &lt;!&ndash;        </a-list>&ndash;&gt;-->
      <!--      </a-tab-pane>-->
    </a-tabs>
  </div>
  <a-modal v-model:visible="visible" title="修改" centered @ok="handleOk">
    <a-form
      v-if="visible === 'password'"
      ref="passwordRef"
      :model="form.passwordForm"
      :rules="passwordRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item v-bind="validateInfos.oldPassword" label="旧密码">
        <a-input-password
          v-model:value="form.passwordForm.oldPassword"
          placeholder="请输入旧密码"
        />
      </a-form-item>
      <a-form-item v-bind="validateInfos.password" label="新密码">
        <a-input-password
          v-model:value="form.passwordForm.password"
          placeholder="请输入新密码"
        />
      </a-form-item>
      <a-form-item v-bind="validateInfos.confirmPassword" label="确认密码">
        <a-input-password
          v-model:value="form.passwordForm.confirmPassword"
          placeholder="请输入确认密码"
        />
      </a-form-item>
    </a-form>
    <a-form
      v-else
      ref="safeRef"
      :model="safeForm"
      :rules="safeRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item
        name="account"
        :label="visible === 'mobile' ? '新手机号码' : '新邮箱地址'"
      >
        <a-input
          autocomplete="off"
          v-model:value="safeForm.account"
          :placeholder="
            visible === 'mobile' ? '请输入新手机号' : '请输入新邮箱地址'
          "
        />
      </a-form-item>
      <a-form-item name="code" label="验证码">
        <a-row type="flex">
          <a-col :flex="1">
            <a-input v-model:value="safeForm.code" placeholder="请输入验证码" />
          </a-col>
          <a-col class="ml-10">
            <a-button
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode"
            >
              {{ btn.title }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, reactive, toRefs } from 'vue';
//  List,Select,
import { Col, Form, Radio, Row, Tabs, message } from 'ant-design-vue';
import IssAvatar from '@/components/avatar';
// import { desensitization } from '@/utils';
// import { validMobile, validEmail } from '@/utils/validation';
import loginApi from '@/api/login';
import personalApi from '@/api/personal';
// import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
// import store from "@/store";
// import IssImageUpload from '@/components/imageUpload';
import { RightOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    RightOutlined,
    // AList: List,
    // AListItem: List.Item,
    // AListItemMeta: List.Item.Meta,
    IssAvatar,
    // ASelect: Select,
    // ASelectOption: Select.Option,
    // IssImageUpload,
  },
  setup() {
    const formRef = ref();
    const passwordRef = ref();
    const safeRef = ref();
    const router = useRouter();
    // const route = useRoute();
    const store = useStore();
    const userId = store.state.account.user.id;
    const state = reactive({
      confirmLoading: false,
      visible: false,
      dataSource: [],
      timer: null,
      showVal: null,
      btn: {
        disabled: false,
        title: '获取验证码',
      },
      activeUpload: '',

      // safeRules: {
      //   account: [
      //     required,
      //     {
      //       validator: (rule, value) => {
      //         if (this.visible === 'mobile') {
      //           return validMobile(value)
      //               ? Promise.resolve()
      //               : Promise.reject('请输入合法的手机号码');
      //         }
      //         return validEmail(value)
      //             ? Promise.resolve()
      //             : Promise.reject('请输入合法的邮箱地址');
      //       },
      //     },
      //   ],
      //   code: required,
      // },
    });
    const form = reactive({
      sex: '',
      passwordForm: { oldPassword: '', password: '', confirmPassword: '' },
      safeForm: { account: '', code: '' },
      id: userId ? userId : '',
      // area: null,
      userName: '',
      avatar: '',
    });

    const required = { required: true, whitespace: true, message: '不能为空' };
    const { validateInfos } = Form.useForm(form.passwordForm, {
      oldPassword: required,
      password: [
        required,
        {
          pattern:
            /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
          message: '长度为8-20位，必须同时含有字母(大小写)、数字和符号',
        },
      ],
      confirmPassword: [
        required,
        {
          validator: (rule, value) =>
            value === form.passwordForm.password
              ? Promise.resolve()
              : Promise.reject('两次输入密码不匹配'),
        },
      ],
    });
    // 基本信息校验
    const formRules = Form.useForm(form, {});

    const getUserInfo = () => {
      personalApi.getItemById('', userId).then(data => {
        if (data) {
          // todo 存储用户信息
          store.commit('account/setUserHeader', data);
          Object.assign(form, data);
        }

        // state.dataSource = [
        //   {
        //     key: 'password',
        //     title: '账号密码',
        //     description: '当前密码强度：强',
        //   },
        //   {
        //     key: 'mobile',
        //     title: '密保手机',
        //     description: `已绑定手机：${desensitization(data.mobile)}`,
        //   },
        //   {
        //     key: 'email',
        //     title: '备用邮箱',
        //     description: `已绑定邮箱：${desensitization(data.email)}`,
        //   },
        // ];
      });
    };

    onMounted(() => {
      getUserInfo();
    });

    return {
      ...toRefs(state),
      form,
      formRef,
      validateInfos,
      formRules,
      safeRef,
      userId,
      gotoBack() {
        router.push({
          path: '/personal',
        });
      },
      handleClickBySave() {
        // 基础信息不做必填校验
        // formRef.value.validate().then(() => {
        console.log('form', form);
        personalApi.update('personal:update', form).then(res => {
          if (res) {
            location.reload();
          }
        });
        // });
      },

      handleClickByUpdate(key) {
        if (state.showVal !== key && state.showVal !== 'password') {
          state.btn = { disabled: false, title: '获取验证码' };
          clearInterval(state.timer);
          state.timer = null;
        }
        state.visible = key;
        state.showVal = key;
        switch (key) {
          case 'password':
            form.passwordForm = {
              oldPassword: '',
              password: '',
              confirmPassword: '',
            };
            break;
          case 'mobile':
          case 'email':
            form.safeForm = { account: '', code: '' };
            safeRef.value.resetFields();
            break;
          default:
            break;
        }
      },
      handleGetCode() {
        safeRef.value.validateFields('account').then(() => {
          state.btn = { disabled: true, title: '重新获取(60s)' };
          let count = 60;
          state.timer = setInterval(() => {
            if (count === 1) {
              state.btn = { disabled: false, title: '获取验证码' };
              clearInterval(state.timer);
              state.timer = null;
            } else {
              count--;
              state.btn.title = `重新获取(${count}s)`;
            }
          }, 1000);
          loginApi.getCode(form.safeForm.account).then(res => {
            if (res) {
              message.success('验证码已发送，请注意查收');
            }
          });
        });
      },
      handleOk() {
        switch (state.visible) {
          case 'password':
            this.handlePassword();
            break;
          case 'mobile':
          case 'email':
            this.handleSafe();
            break;
          default:
            break;
        }
      },
      handlePassword() {
        passwordRef.value.validate().then(() => {
          state.confirmLoading = true;
          personalApi
            .updatePassword({
              ...form.passwordForm,
              id: form.id,
            })
            .then(res => {
              if (res) {
                localStorage.clear();
                location.reload();
              }
            })
            .finally(() => {
              state.confirmLoading = false;
            });
        });
      },
      handleSafe() {
        safeRef.value.validate().then(() => {
          state.confirmLoading = true;
          loginApi
            .verifyCode(form.safeForm)
            .then(data => {
              if (data) {
                form[state.visible] = form.safeForm.account;
                personalApi
                  .update('personal:update', form)
                  .then(res => {
                    if (res) {
                      localStorage.clear();
                      location.reload();
                    }
                  })
                  .finally(() => {
                    state.confirmLoading = false;
                  });
              } else {
                state.confirmLoading = false;
                message.error('验证失败，请重新获取验证码');
              }
            })
            .catch(() => (state.confirmLoading = false));
        });
      },
      getValue(value) {
        form.avatar = value;
      },
    };
  },

  // data() {
  // const required = { required: true, message: '不能为空' };
  // return {
  // form: { sex: {} },
  // rules: { name: required },
  // passwordRules: {
  //   oldPassword: required,
  //   password: [
  //     required,
  //     {
  //       pattern:
  //           /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
  //       message: '长度为8-20位，必须同时含有字母(大小写)、数字和符号',
  //     },
  //   ],
  //   confirmPassword: [
  //     required,
  //     {
  //       validator: (rule, value) =>
  //           value === this.passwordForm.password
  //               ? Promise.resolve()
  //               : Promise.reject('两次输入密码不匹配'),
  //     },
  //   ],
  // },
  // safeForm: { account: '', code: '' },
  // safeRules: {
  //   account: [
  //     required,
  //     {
  //       validator: (rule, value) => {
  //         if (this.visible === 'mobile') {
  //           return validMobile(value)
  //               ? Promise.resolve()
  //               : Promise.reject('请输入合法的手机号码');
  //         }
  //         return validEmail(value)
  //             ? Promise.resolve()
  //             : Promise.reject('请输入合法的邮箱地址');
  //       },
  //     },
  //   ],
  //   code: required,
  // },
  // };
  // },
  // created() {
  //   this.getUserInfo();
  // },
  // methods: {
  // handleClickBySave() {
  //   formRef.value.validate().then(() => {
  //     personalApi
  //         .update('personal:update', this.form)
  //         .then(() => location.reload());
  //   });
  // },
  // handleClickByUpdate(key) {
  //   if (this.showVal !== key && this.showVal !== 'password') {
  //     this.btn = { disabled: false, title: '获取验证码' };
  //     clearInterval(this.timer);
  //     this.timer = null;
  //   }
  //   this.visible = key;
  //   this.showVal = key;
  //   switch (key) {
  //     case 'password':
  //       this.passwordForm = {
  //         oldPassword: '',
  //         password: '',
  //         confirmPassword: '',
  //       };
  //       break;
  //     case 'mobile':
  //     case 'email':
  //       this.safeForm = { account: '', code: '' };
  //       this.$refs.safeRef?.resetFields();
  //       break;
  //     default:
  //       break;
  //   }
  // },
  // handleGetCode() {
  //   this.$refs.safeRef.validateFields('account').then(() => {
  //     this.btn = { disabled: true, title: '重新获取(60s)' };
  //     let count = 60;
  //     this.timer = setInterval(() => {
  //       if (count === 1) {
  //         this.btn = { disabled: false, title: '获取验证码' };
  //         clearInterval(this.timer);
  //         this.timer = null;
  //       } else {
  //         count--;
  //         this.btn.title = `重新获取(${count}s)`;
  //       }
  //     }, 1000);
  //     loginApi.getCode(this.safeForm.account).then(() => {
  //       this.$message.success('验证码已发送，请注意查收');
  //     });
  //   });
  // },
  // handleOk() {
  //   switch (this.visible) {
  //     case 'password':
  //       this.handlePassword();
  //       break;
  //     case 'mobile':
  //     case 'email':
  //       this.handleSafe();
  //       break;
  //     default:
  //       break;
  //   }
  // },
  // handlePassword() {
  //   this.$refs.passwordRef.validate().then(() => {
  //     this.confirmLoading = true;
  //     personalApi
  //         .updatePassword({
  //           ...this.passwordForm,
  //           id: this.form.id,
  //         })
  //         .then(() => {
  //           localStorage.clear();
  //           location.reload();
  //         })
  //         .finally(() => {
  //           this.confirmLoading = false;
  //         });
  //   });
  // },
  // handleSafe() {
  //   this.$refs.safeRef.validate().then(() => {
  //     this.confirmLoading = true;
  //     loginApi
  //         .verifyCode(this.safeForm)
  //         .then(data => {
  //           if (data) {
  //             this.form[this.visible] = this.safeForm.account;
  //             personalApi
  //                 .update('personal:update', this.form)
  //                 .then(() => {
  //                   localStorage.clear();
  //                   location.reload();
  //                 })
  //                 .finally(() => {
  //                   this.confirmLoading = false;
  //                 });
  //           } else {
  //             this.confirmLoading = false;
  //             this.$message.error('验证失败，请重新获取验证码');
  //           }
  //         })
  //         .catch(() => (this.confirmLoading = false));
  //   });
  // },
  // },
};
</script>

<style lang="less" scoped>
.iss-main {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.iss-title {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 12px;
}

.contactFrequency {
  :deep(.ant-tabs .ant-tabs-bar) {
    border-bottom: none;
  }
}
.gotoBack {
  text-align: right;
  padding: 12px;
  color: #1d2129;
  cursor: pointer;
}
</style>
